import {Dayjs} from "dayjs";

const isInRange = (dayA: Dayjs, startDay: Dayjs | null | undefined, endDay: Dayjs | null | undefined, hoverDay: Dayjs | null | undefined) => {
    if (endDay == null) {
        if (startDay == null || hoverDay == null) {
            return false;
        }
        if (startDay && hoverDay && startDay.isAfter(hoverDay)) {
            return false
        }
        return dayA.isBetween(startDay.add(-1, 'day'), hoverDay.add(-1, 'day'), null, '[]');
        // return dayA.isAfter(startDay) && dayA.isBefore(hoverDay)
    }
    if (!startDay) {
        return false
    }
    return dayA.isBetween(startDay.add(-1, 'day'), endDay, null, '[]');
    // return dayA.isAfter(startDay) && dayA.isBefore(endDay);

};

export default isInRange;
