import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {useGlobalContext} from "../store/appContext";
import {CircularProgress, TextField} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import axios, {AxiosResponse} from "axios";
import {MyObject} from "../store/types";

axios.defaults.withCredentials = true

export default function LoginDialog({ apiUrl }: { apiUrl: string}) {

    const {user, setUser, isMobile, selectedObject, setSelectedObject, setMyObjects, checkSessionTrigger} = useGlobalContext()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [progressing, setProgressing] = useState(false);

    const setUserProsFromResponse = (response: AxiosResponse): void => {
        setUser(response.data.user)
        if (response.data.user.objects?.[0]) {
            const apiObjects = response.data.user.objects as MyObject[]
            let selectIndex = 0
            if (selectedObject?.id) { // Object already selected, select same one with updated bookings
                selectIndex = apiObjects.map((o => o.id)).indexOf(selectedObject.id)
            }
            setSelectedObject(apiObjects?.[selectIndex])
            setMyObjects(apiObjects)
        }

    }

    const checkSession = async () => {
        try {
            setProgressing(true)
            const response = await axios.get(apiUrl + 'partnersession', {});
            if (response.data.user) {
                console.log(response.data.user)
                setUserProsFromResponse(response)
            }
            setProgressing(false)
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    useEffect(() => {
        console.log('-_use-effect-checkSessionTrigger', checkSessionTrigger)
        checkSession().then();
    }, [checkSessionTrigger]);


    const handleLogin = async () => {
        try {
            const response = await axios.post(apiUrl + 'partnerlogin', {
                username: username.toLowerCase(),
                password: password,
            });
            setProgressing(false)
            if (response.data.user) {
                setUserProsFromResponse(response)
            } else {
                alert('Error');
            }
            console.log('Login successful:', response.data);
        } catch (error) {
            console.error('Error during login:', error);
        }
    };


    return (
        <>
            <Grid
                container
                style={{
                    height: '100vh',
                    display: !user ? '' : 'none',
            }}
            >
                <Grid item xs={12} className="text-center items-center justify-center p-4" style={{
                    height: '100vh',
                    display: 'flex',
                }}>
                    <Grid item>
                        <div
                            className={(!isMobile ? 'shadow-2xl' : '') + ' px-8 py-6'}
                            style={{
                                borderRadius: '12px',
                                width: '360px',
                                display: 'inline-block',
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12} className="text-center items-center p-4">
                                    <img src="/partner-csr-logo.png" style={{
                                        height: '100px',
                                        justifySelf: 'end',
                                        display: 'inline',
                                    }}/>
                                </Grid>

                                <Grid className="text-2xl justify-center font-thin py-4">
                                    <LockIcon
                                        color="primary"
                                        className="mb-2 mr-3"
                                        style={{
                                            fontSize: '1.3em'
                                        }}
                                    />
                                    Partner Login
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="E-mail"
                                        variant="outlined"
                                        value={username}
                                        onChange={(e) => {
                                            setUsername(e.target.value)
                                        }}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} className="py-4">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        fullWidth
                                        onClick={() => {
                                            handleLogin().then()
                                        }}
                                    >
                                        {progressing ? <CircularProgress size={24} color="inherit"/> : 'Login'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
