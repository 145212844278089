import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {useGlobalContext} from "../store/appContext";
import axios from "axios";
import { Chip, CircularProgress, IconButton, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import {BookedDay} from '../store/types'
import DeleteForever from '@mui/icons-material/DeleteForever';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import dayjs from "dayjs";
import { Link } from 'wouter'
import { getButtonProps } from '../methods/shared'
import { useHashLocation } from "wouter/use-hash-location";

axios.defaults.withCredentials = true

export default function OwnerBookingsList({ onDeleteClick }: { onDeleteClick: (booking: BookedDay) => void }) {
  const {calendarBookings, isMobile, usedTheme, setSelectedBooking} = useGlobalContext()
  const [location, setLocation] = useHashLocation();

  let [tabValue, setTabValue] = useState<number>(0)

    return (
      <Grid
        container
        className="bookings-list items-center justify-center text-center"
        style={{
          marginBottom: '300px'
      }}>
        <div className={'pt-2 pb-5'}>
          <Link href="/upcoming">
            <Button
              {...getButtonProps(location, '/owner_reservations/upcoming')}
            >
              Upcoming
            </Button>
          </Link>
          <Link href="/history">
            <Button
              {...getButtonProps(location, '/owner_reservations/history')}
            >
              History
            </Button>
          </Link>
        </div>
        {
          calendarBookings
            .filter(booking => booking.ownerReservation)
            .filter(booking => {
              const typeName = location.split('/').pop()
              return typeName === 'upcoming'
                ? dayjs(booking.endDay).isAfter(dayjs(), 'day')
                : !dayjs(booking.endDay).isAfter(dayjs(), 'day')
            })
            .map((booking, index) => {
              const typeName = location.split('/').pop()
              return (
                <Grid
                  key={`${booking._id} ${index}`}
                  container
                  justifyContent="center"
                  className="py-1"
                >
                  <Grid item xs={12}
                        className="py-0 my-0 font-bold"
                  >
                    <Chip
                      label={booking.startDay?.format('DD.MM.YYYY') + ' - ' + booking.endDay?.format('DD.MM.YYYY') + ' ' + booking.ownerNotes}
                      className="mr-2 chip-dates"
                      variant="outlined"
                      onClick={() => {
                        console.log('booking', booking)
                        setSelectedBooking(booking ? booking as BookedDay : null)
                      }}
                    />
                    <Chip
                      label={booking.nights + ' nights'}
                      style={{
                        background: usedTheme?.palette.secondary.light,
                      }}
                      className="mx-2 chip-days"
                      variant="outlined"
                      onClick={() => {
                        setSelectedBooking(booking ? booking as BookedDay : null)
                      }}
                    />
                    {(!isMobile || isMobile) && typeName === 'upcoming' && (
                      <IconButton onClick={() => {
                        if (window.confirm('Delete?')) {
                          onDeleteClick(booking as BookedDay)
                        }
                      }}>
                        <DeleteForever style={{ fontSize: '1.6rem', color: 'red' }} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>

              )
            })
        }

      </Grid>

    );
};
