import React, {SyntheticEvent, useEffect, useState} from 'react'
import './App.css';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import RangeSelector from "./components/rangeSelector";
import './style.css'
import BottomNav from "./components/BottomNav";
import dayjs, {Dayjs} from "dayjs";
import AppToolbar from "./components/AppToolbar";
import {MyGlobalContext} from "./store/appContext";
import {BookedDay, Booking, DateFormat, MyObject, User} from './store/types'
import LoginDialog from "./components/LoginDialog";
import {CssBaseline, Switch, ThemeProvider} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import theme, {darkTheme} from "./theme";
import {AxiosResponse} from "axios";
import csrAPI, {apiUrl} from "./api/csrAPI";
import OwnerBookingsList from './components/OwnerBookingsList'
import CustomerBookingsList from './components/CustomerBookingsList'
import { Router, Link, Route, Switch as RouteSwitch, Redirect } from 'wouter'
import { useHashLocation } from "wouter/use-hash-location";

import Button from '@mui/material/Button'
import { getButtonProps } from './methods/shared'

function App() {
    let [creatingReservation, setCreatingReservation] = useState<boolean>(false);
    let [startDay, setStartDay] = useState<dayjs.Dayjs | null>(null);
    let [endDay, setEndDay] = useState<dayjs.Dayjs | null>(null);
    let [notes, setNotes] = useState<string>('');
    let [checkSessionTrigger, setCheckSessionTrigger] = useState<number>(0);
    let [myObjects, setMyObjects] = useState<MyObject[]>([])
    let [selectedObject, setSelectedObject] = useState<MyObject | null>(null)
    let [selectedBooking, setSelectedBooking] = useState<BookedDay | null>(null);
    const [user, setUser] = useState<User | null>(null)
    const [calendarBookings, setCalendarBookings] = React.useState<Booking[]>([]);
    const [location, setLocation] = useHashLocation();

    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const prefersDarkMode = false;
    const [darkMode, setDarkMode] = useState<boolean>(prefersDarkMode);
    const isMobile = useMediaQuery('(max-width:600px)');
    const usedTheme = darkMode ? darkTheme : theme

    const nights = startDay?.isValid() && endDay?.isValid() ? dayjs(endDay.add(0, 'day')).diff(startDay, 'days') : 0;

    const [value, setValue] = useState(0);

    const handleChange = (e: SyntheticEvent<Element, Event>, newValue: number) => {
        setValue(newValue);
    };

    const createReservation = async (startDay: Dayjs | null, endDay: Dayjs | null, notes: string): Promise<AxiosResponse | undefined> => {
        if (!startDay || !endDay || !selectedObject?.id) {
            return Promise.resolve(undefined);
        }

        const allowedCharactersRegex = /^[a-zA-Z0-9\s.,!?()-]*$/;
        const sanitizedNotes = notes.split('').filter(char => allowedCharactersRegex.test(char)).join('')

        return csrAPI.createReservation({
            startDate: startDay.format('YYYY-MM-DD') as DateFormat,
            endDate: endDay.format('YYYY-MM-DD') as DateFormat,
            notes: sanitizedNotes,
            forId: selectedObject?.id,
        })
    }
    const deleteReservation = async (selectedBooking: BookedDay | null): Promise<AxiosResponse | undefined> => {
        if (!selectedBooking) {
            return Promise.resolve(undefined);
        }

        return csrAPI.deleteReservation(selectedBooking._id)
    }

    const globalContextProvider = {
        user,
        setUser,
        isMobile,
        darkMode,
        setDarkMode,
        usedTheme,
        myObjects,
        setMyObjects,
        selectedObject,
        setSelectedObject,
        checkSessionTrigger,
        setCheckSessionTrigger,
        selectedBooking,
        setSelectedBooking,
        calendarBookings,
        setCalendarBookings
    }

    const deleteBooking = async (booking: BookedDay | null) => {
        setCreatingReservation(true);
        const r = await deleteReservation(booking);
        if (r?.data?.id && r?.data?.status === 'ok') {
            setSelectedBooking(null)
            setStartDay(null)
            setEndDay(null)
            setNotes('')
            setCheckSessionTrigger(checkSessionTrigger + 1)
        } else {
            alert('Delete error, try again')
        }
        setCreatingReservation(false);
    }

    useEffect(() => {
        if (selectedObject) {
            setCalendarBookings(selectedObject.bookings.map(booking => ({
                ...booking,
                startDay: dayjs(booking.dateStart),
                endDay: dayjs(booking.dateEnd),
                ownerFeePaidDay: booking.ownerFeePaidDate ? dayjs(booking.ownerFeePaidDate) : null,
            })));
        }
    }, [selectedObject]);

    useEffect(() => {
        setSelectedBooking(null)
        setStartDay(null)
        setEndDay(null)
    }, [location, selectedObject]);

    type ButtonProps = {
        style: React.CSSProperties;
        variant: 'outlined' | 'text';
    };

    return (
      <ThemeProvider theme={usedTheme}>
          <CssBaseline>
              <MyGlobalContext.Provider value={globalContextProvider}>
                  <Router hook={useHashLocation} base="">
                      <Redirect to="/calendar" />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <div
                        className={'App ' + (isMobile ? 'mobile-view' : '')}
                        style={{
                            backgroundColor: usedTheme?.palette.background.default
                        }}
                      >
                          {user?._id === '' && (
                            <Switch
                              checked={darkMode}
                              onChange={() => {
                                  setDarkMode(!darkMode)
                              }}
                              color="primary"
                              inputProps={{ 'aria-label': 'toggle dark mode' }}
                            />
                          )}
                          <LoginDialog apiUrl={apiUrl} />
                          {user && !selectedObject && (
                            <div>
                                <AppToolbar apiUrl={apiUrl} />
                                <div>No objects</div>
                            </div>
                          )}
                          {user && selectedObject && (
                            <>
                                <AppToolbar apiUrl={apiUrl} />

                                <div className="pt-8 pb-5">
                                    <Link href="/calendar">

                                        <Button {...getButtonProps(location, '/calendar')} >
                                            Calendar
                                        </Button>
                                    </Link>
                                    <Link href="/owner_reservations/upcoming">
                                        <Button
                                          {...getButtonProps(location, '/owner_reservations')}
                                        >
                                            My reservations
                                        </Button>
                                    </Link>
                                    <Link href="/payments">
                                        <Button
                                          {...getButtonProps(location, '/payments')}
                                        >
                                            Payments
                                        </Button>
                                    </Link>
                                </div>


                                <RouteSwitch>
                                    <Route path="/calendar">
                                        <div
                                          className={calendarBookings.length ? 'pt-4 pb-20' : 'pt-10 pb-20'}
                                        >
                                            <RangeSelector
                                              startDay={startDay} endDay={endDay}
                                              setStartDay={setStartDay} setEndDay={setEndDay}
                                            />
                                        </div>
                                    </Route>

                                    <Route path="/payments">
                                        <div className="pb-5" style={{
                                                overflow: 'auto',
                                            }}>
                                            <CustomerBookingsList />
                                        </div>
                                    </Route>


                                    <Router base="/owner_reservations">
                                        <div className="pb-5">
                                            <OwnerBookingsList
                                              onDeleteClick={(booking) => {
                                                  deleteBooking(booking)
                                              }}
                                            />
                                        </div>
                                    </Router>


                                </RouteSwitch>


                                { (location === '/calendar' || selectedBooking) && (
                                <BottomNav
                                  startDay={startDay}
                                  endDay={endDay}
                                  notes={notes}
                                  setNotes={setNotes}
                                  nights={nights}
                                  creatingReservation={creatingReservation}
                                  onClearDays={() => {
                                      setStartDay(null)
                                      setEndDay(null)
                                  }}
                                  onActionClick={async () => {
                                      setCreatingReservation(true);
                                      const r = await createReservation(startDay, endDay, notes);
                                      console.log('rr', r)
                                      if (r?.data?.id && r?.data?.status === 'ok') {
                                          setStartDay(null)
                                          setEndDay(null)
                                          setNotes('')
                                      } else {
                                          alert('Save error, try again')
                                      }
                                      setCreatingReservation(false);
                                      setCheckSessionTrigger(checkSessionTrigger + 1)
                                  }}
                                  onDeleteClick={() => {
                                      if (window.confirm('Delete?')) {
                                          deleteBooking(selectedBooking)
                                      }
                                  }}
                                />
                                )}
                            </>
                          )}
                      </div>
                  </LocalizationProvider>
                  </Router>
              </MyGlobalContext.Provider>
          </CssBaseline>
      </ThemeProvider>
    );
}

export default App;
