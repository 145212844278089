import {IconButton, MenuItem, Select} from "@mui/material";
import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import Grid from "@mui/material/Grid";
import {useGlobalContext} from "../store/appContext";
import Button from "@mui/material/Button";
import axios from "axios";

axios.defaults.withCredentials = true

export default function AppToolbar({ apiUrl }: { apiUrl: string}) {

    const {user, usedTheme, setUser, selectedObject, setMyObjects, setSelectedObject, myObjects, isMobile} = useGlobalContext()

    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const onLogout = async () => {
        try {
            const response = await axios.get(apiUrl + 'logout', {});
            // setProgressing(false)
            console.log('rrr, ', response)
            if (response.data.item === 'destroyed') {
                setUser(null)
                setSelectedObject(null)
                setMyObjects([])
                // setUserProsFromResponse(response)
            } else {
                // alert('Error');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }

    return (
        <Grid container className="shadow-xl" style={{
            backgroundColor: usedTheme?.palette.toolbar.main,
            borderBottom: '1px solid #ccc',
        }}>
            <Grid item xs={isMobile ? 6 : 4}
                  className={ 'flex items-center justify-start pl-7 ' + (isMobile ? 'text-left pt-3' : '' ) }
                  order={{ xs: 1 }}>
                { myObjects.length > 1000 && (
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}>
                        <MenuIcon/>
                    </IconButton>
                )}
                <div className="" style={{
                    display: 'inline'
                }}>
                    {user?.firstName} {user?.lastName}<br/>
                    <Button onClick={onLogout}>Logout</Button>
                </div>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4} className="flex items-center justify-center" order={{ xs: isMobile ? 3 : 2 }}>
                <Grid container className={isMobile ? 'pb-5' : ''}>
                    <Grid item xs={12}>
                        <img
                            src={'https://s3.eu-central-1.amazonaws.com/img.costasolrent.com/objectImages/large/' + selectedObject?.objectImages[0].image}
                            style={{
                                height: '60px',
                                display: "inline-grid",
                                borderRadius: '10px',
                                marginTop: '10px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className="pt-3">
                        <Select
                            labelId=""
                            value={selectedIndex}
                            onChange={(e) => {
                                setSelectedObject(myObjects[e.target.value as number])
                                setSelectedIndex(e.target.value as number)
                            }}
                            style={{
                                height: '30px',
                            }}
                        >
                            {myObjects.map((myObject, index) => (
                                <MenuItem
                                    key={myObject.id}
                                    value={index}
                                    className="text-xl"
                                >
                                    {myObject.objectName}
                                </MenuItem>
                            ))}

                        </Select>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={isMobile ? 6 : 4}
                  className={isMobile ? 'text-center items-center p-4' : 'text-end items-end p-4'}
                  order={{ xs: isMobile ? 2 : 3 }}
            >
                <img src="/partner-csr-logo.png" style={{
                    height: isMobile ? '60px' : '100px',
                    justifySelf: 'end',
                    display: 'inline',
                }}/>
            </Grid>
        </Grid>

    );
};
