import { createTheme, Theme } from '@mui/material/styles';

const theme: Theme = createTheme({
    palette: {
        primary: {
            main: '#219BC8',
        },
        secondary: {
            main: '#ffcb01',
        },
        background: {
            default: '#fafafa',
            paper: '#f5f5f5',
        },
        toolbar: {
            main: '#eeeeee',
        },
    },
});

const darkTheme: Theme = createTheme({
    // ...theme,
    palette: {
        primary: {
            main: '#219BC8',
        },
        secondary: {
            main: '#ffa000',
        },
        // ...theme.palette,
        background: {
            default: '#212121',
            paper: '#424242',
        },
        toolbar: {
            main: '#616161',
        },
        mode: 'dark',
    },
});

export { theme, darkTheme };
export default theme;
