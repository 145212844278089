import * as React from "react";
import {Dayjs} from "dayjs";
import CustomPickersDay from "./customPickersDay";

const isSelectedStartOrEnd = (
    dayA: Dayjs,
    startDay: Dayjs | null | undefined,
    endDay: Dayjs | null | undefined,
    dayB: Dayjs | null | undefined,
) => {
    if (startDay?.isSame(dayA) || endDay?.isSame(dayA) || dayA?.isSame(dayB)) {
        return true
    }
    if (dayB == null) {
        return false;
    }
    return false
};

const Day = React.memo((
    props: any,
) => {
    const {day, selectedDay, hoveredDay, startDay, endDay, newInRange, dayReservation, ...other} = props;
    // console.log('return day', day.toISOString())
    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{px: 2.5}}
            disableMargin
            selected={false}
            isSelected={isSelectedStartOrEnd(day, startDay, endDay, hoveredDay)}
            isInRange={newInRange}
            dayReservation={dayReservation}
        />
    );
});

export default Day;
