import * as React from "react";
import {styled} from "@mui/material/styles";
import {PickersDay, PickersDayProps} from "@mui/x-date-pickers/PickersDay";
import {Dayjs} from "dayjs";
import {BookedDay} from "../store/types";

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
    isSelected?: boolean
    isInRange?: boolean
    dayReservation: BookedDay | null,
    newInRange?: boolean
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => !['isSelected', 'isInRange', 'newInRange', 'dayReservation'].includes(prop as string),
})<CustomPickerDayProps>(({theme, isSelected, isInRange, dayReservation}) => ({
    borderRadius: 0,
    ...(dayReservation?.ownerReservation && {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(dayReservation?._id && !dayReservation.ownerReservation && {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isSelected && {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isInRange && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
        },
    }),
    ...(isSelected && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    ...({
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as React.ComponentType<CustomPickerDayProps>;

export default CustomPickersDay;
