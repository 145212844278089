import { createContext, useContext } from "react"
import {BookedDay, Booking, MyObject, User} from './types'
import {Theme} from "@mui/material/styles";

export type GlobalContent = {
    user: User | null
    setUser:(user: User | null) => void
    isMobile: boolean,
    darkMode: boolean
    setDarkMode:(mode: boolean) => void
    usedTheme: Theme | null,
    setMyObjects:(objects: MyObject[] | []) => void
    myObjects: MyObject[],
    setSelectedObject:(object: MyObject | null) => void
    selectedObject: MyObject | null,
    checkSessionTrigger: number,
    setCheckSessionTrigger:(number: number) => void
    selectedBooking: BookedDay | null,
    setSelectedBooking:(booking: BookedDay | null) => void
    calendarBookings: Booking[]
    setCalendarBookings:(bookings: Booking[]) => void
}

export const MyGlobalContext = createContext<GlobalContent>({
    user: null,
    setUser: () => {},
    isMobile: false,
    darkMode: false,
    setDarkMode: () => {},
    usedTheme: null,
    setMyObjects: () => {},
    myObjects: [],
    setSelectedObject: () => {},
    selectedObject: null,
    checkSessionTrigger: 0,
    setCheckSessionTrigger: () => {},
    selectedBooking: null,
    setSelectedBooking: () => {},
    calendarBookings: [],
    setCalendarBookings: () => {},

})
export const useGlobalContext = () => useContext(MyGlobalContext)
