import React from "react";
import Grid from "@mui/material/Grid";
import {useGlobalContext} from "../store/appContext";

export default function Legend() {

    const {usedTheme, isMobile} = useGlobalContext()

    const styleProps = {
        display: 'inline-flex',
        borderRadius: 28,
        height: '20px',
        width: '20px',
        marginRight: '20px',
        position: 'relative',
        top: '4px',
        marginLeft: '30px',
    }

    return (
        <Grid item xs={12}
              justifyContent="center"
              style={{
                  alignItems: 'left',
                  display: 'flex',
                  paddingTop: '30px',
                  paddingBottom: '20px',
              }}
        >
            <Grid container style={{
                maxWidth: isMobile ? '260px' : '800px',
            }} spacing={1} >
                <Grid item xs={12} md={4} className="text-left">
                    <div
                        style={{
                            ...(styleProps as object),
                            background: usedTheme?.palette.error.light,
                        }}
                    />
                    Costasolrent.com
                </Grid>
                <Grid item xs={12} md={4} className="text-left">
                    <div
                        style={{
                            ...(styleProps as object),
                            background: usedTheme?.palette.secondary.main,
                        }}
                    />
                    Owner reservation
                </Grid>
                <Grid item xs={12} md={4} className="text-left">
                    <div
                        style={{
                            ...(styleProps as object),
                            background: usedTheme?.palette.primary.main,
                        }}
                    />
                    Selected days
                </Grid>
            </Grid>
        </Grid>

    );
};
