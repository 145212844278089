import React from "react";
import {useGlobalContext} from "../store/appContext";
import axios from "axios";
import './CustomerBookingsTable.scss';
import dayjs from 'dayjs'
import {Booking, BookingPayment} from '../store/types'

axios.defaults.withCredentials = true

export default function CustomerBookingsList() {

    const {calendarBookings} = useGlobalContext()

    let bookingsAndRentPaymentsCombined: (Booking | BookingPayment)[] = []
    calendarBookings
        .filter(b => !b.ownerReservation)
        .forEach(b => {
            if (b.bookingPayments?.length) {
                b.bookingPayments.forEach(bp => {
                    bookingsAndRentPaymentsCombined.push({
                        ...bp,
                        startDay: dayjs(bp.dateStart),
                        endDay: dayjs(bp.dateEnd),
                        ownerFeePaidDay: bp.ownerFeePaidDate ? dayjs(bp.ownerFeePaidDate) : null,

                        customerName: b.customerName,
                        customerCountry: b.customerCountry,
                        customerDocumentNumber: b.customerDocumentNumber,
                    })
                })
            } else if (dayjs(b.dateEnd).isBefore(dayjs(), 'day')) {
                bookingsAndRentPaymentsCombined.push(b)
            }
    })

    bookingsAndRentPaymentsCombined = bookingsAndRentPaymentsCombined
        .sort((a, b) => dayjs(b.dateStart).diff(dayjs(a.dateStart)))

    return (
        <table className="customer-bookings" style={{
            marginBottom: '180px',
            minWidth: '1200px',
        }}>
            <thead>
            <tr>
                <th>&nbsp;</th>
                <th className={'left'}>Period</th>
                <th className={'left'}>Customer name</th>
                <th className={'left'}>Country</th>
                <th className={'left'}>Document number</th>
                <th>Rent</th>
                <th>CSR fee</th>
                <th>Owner fee</th>
                <th>Paid to owner</th>
                <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
                {bookingsAndRentPaymentsCombined.map((bookingOrPayment, index) => (
                    <tr
                        key={bookingOrPayment._id}
                    >
                        <td>&nbsp;</td>
                        <td className={'left'}>{bookingOrPayment.startDay?.format('DD.MM.YYYY')} - {bookingOrPayment.endDay?.format('DD.MM.YYYY')}</td>
                        <td className={'left'}>{bookingOrPayment.customerName}</td>
                        <td className={'left'}>{bookingOrPayment.customerCountry}</td>
                        <td className={'left'}>{bookingOrPayment.customerDocumentNumber}</td>
                        <td>{bookingOrPayment.rentFee ? bookingOrPayment.rentFee.toLocaleString() + ' €' : ''}</td>
                        <td>{bookingOrPayment.csrRentFee ? bookingOrPayment.csrRentFee.toLocaleString() + ' €' : ''}</td>
                        <td>{bookingOrPayment.ownerFee ? bookingOrPayment.ownerFee.toLocaleString() + ' €' : ''}</td>
                        <td>{bookingOrPayment.ownerFeePaidDay?.format('DD.MM.YYYY') || '-'}</td>
                        <td>&nbsp;</td>
                    </tr>
                ))}
            </tbody>
        </table>

    );
};
