import {BookedDaysMap, Booking} from "../store/types";

const getBookedDays = function getBookedDays(bookings: Booking[]): BookedDaysMap {
    const disabledDays: BookedDaysMap = {};

    bookings.forEach((booking) => {
        const { startDay, endDay } = booking;
        let currentDay = startDay.clone();

        while (currentDay.isBefore(endDay) || currentDay.isSame(endDay, 'day')) {
            disabledDays[currentDay.format('YYYY-MM-DD')] = {
                day: currentDay.clone(),
                _id: booking._id,
                startDay: booking.startDay,
                endDay: booking.endDay,
                ownerReservation: booking.ownerReservation,
                ownerNotes: booking.ownerNotes,
            }
            currentDay = currentDay.add(1, 'day');
        }
    });

    return disabledDays;
}

export default getBookedDays;
