import { ButtonProps } from '@mui/material'

export const getButtonProps = (location: string, path: string): ButtonProps => {
  const isActive = location.startsWith(path)
  return {
    style: {
      borderRadius: '0',
      borderBottom: isActive ? '2px solid rgb(33, 155, 200)' : '',
      margin: '0 5px',
      backgroundColor: isActive ? "transparent" : 'transparent',
      color:  isActive ? "rgb(33, 155, 200)" : 'black',
    },
    variant: isActive ? 'text' : 'text'
  }
}

export default {
  getButtonProps,
}
