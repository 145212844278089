import * as React from 'react';
import {useCallback, useEffect} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import 'dayjs/locale/et'; // Import the Estonian locale
import 'dayjs/locale/en-gb';
import './calendar.scss';
import isInRange from "../methods/isInRange";
import Day from "./day";
import Grid from "@mui/material/Grid";
import {useGlobalContext} from "../store/appContext";
import {BookedDaysMap} from "../store/types";
import getBookedDays from "../methods/getBookedDays";
import Legend from "./Legend";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

dayjs.extend(isBetweenPlugin);
dayjs.locale('en-gb');

interface RangeProps {
    startDay: Dayjs | null;
    endDay: Dayjs | null;
    setStartDay: any,
    setEndDay: any,
}

export default function RangeSelector({
                                          startDay,
                                          setStartDay,
                                          endDay,
                                          setEndDay,
                                      }: RangeProps) {
    const {selectedObject, setSelectedBooking, calendarBookings, setCalendarBookings} = useGlobalContext()

    const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
    const [calendarStartDay, setCalendarStartDay] = React.useState<Dayjs>(dayjs());
    const [bookedDays, setBookedDays] = React.useState<BookedDaysMap>({});
    const [bookedDayKeys, setBookedDayKeys] = React.useState<string[]>([]);
    const [showMonthsCount, setShowMonthsCount] = React.useState<number>(12);

    const handleMonthChange = (value: Dayjs | null): void => {
        if (value?.isValid()) {
            console.log('month change', value?.toISOString(), calendarStartDay.toISOString())
            setCalendarStartDay(value);
        }
    };


    useEffect(() => {
        console.log('__useEffect 2', calendarBookings)
        const calculated = getBookedDays(calendarBookings)
        setBookedDays(calculated)
        setBookedDayKeys(Object.keys(calculated))
    }, [calendarBookings]);

    const isDayDisabled = (day: Dayjs | null | undefined): boolean => {
        if (!day) { return false }
        return bookedDayKeys.includes(day.format('YYYY-MM-DD'))
    };

    const handleDatePick = (value: Dayjs | null): void => {
        setSelectedBooking(null)
        // startDay = value
        // myDate = value
        if (isDayDisabled(value)) {
            // Find booking by day
            const dayKey = value?.format('YYYY-MM-DD')
            if (dayKey && bookedDays[dayKey]) {
                setSelectedBooking(bookedDays[dayKey])
                setStartDay(null)
                setEndDay(null)
                return
            }
            // return alert('Show booking details.' + value?.toISOString())
        }
        if (startDay) {
            if (endDay) {
                setStartDay(value)
                setEndDay(null)
            } else {
                setEndDay(value)
            }
        } else {
            setStartDay(value)
        }
    }

    // const currentYear = dayjs().year();
    const months = Array.from({length: showMonthsCount}, (_, index) => index + 1);

    const onPointerEnter = useCallback((input: any) => {
        const timestamp = input?.target?.dataset?.timestamp / 1000
        const hoveredDay = dayjs.unix(timestamp)
        // console.log('day-useCallback', timestamp, hoveredDay.toISOString())
        setHoveredDay(hoveredDay.add(1, 'day'))
    }, [])

    const daySlotProps = {
        day: (ownerState: any) => ({
            newInRange: isInRange(ownerState.day, startDay, endDay, hoveredDay),
            // newInRange: true,
            // isDayDisabled: isDayDisabled(ownerState.day),
            dayReservation: isDayDisabled(ownerState.day) ? bookedDays[ownerState.day.format('YYYY-MM-DD')] : null,
            onPointerEnter,
        })
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid container spacing={2} style={{
                        maxWidth: '1200px',
                        marginBottom: '200px',
                    }}>
                        <Legend/>
                        {months.map((month, index) => (
                            <Grid
                                key={month}
                                className="month-container"
                                item xs={12} md={6} lg={4}
                            >
                                <div className="text-2xl month-name">
                                    {calendarStartDay.add(index, 'month').format('MMMM YYYY')}
                                </div>
                                <DateCalendar
                                    key={calendarStartDay.add(index, 'month').toISOString()}
                                    onChange={handleDatePick}
                                    onMonthChange={handleMonthChange}
                                    onYearChange={handleMonthChange}
                                    displayWeekNumber
                                    reduceAnimations
                                    referenceDate={calendarStartDay.add(index, 'month')}
                                    slots={{day: Day}}
                                    slotProps={daySlotProps}
                                />
                            </Grid>
                        ))}
                        { showMonthsCount < 30 && (
                            <Grid item className="text-center" xs={12}>
                                <Button onClick={() => {
                                    setShowMonthsCount(showMonthsCount + 6)
                                }}
                                        variant="outlined"
                                >
                                    <ExpandMoreIcon style={{ fontSize: '2rem' }} />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </>
    );
}
