import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import dayjs, {Dayjs} from "dayjs";
import {Chip, CircularProgress, TextField} from "@mui/material";
import {useGlobalContext} from "../store/appContext";

interface BottomBarProps {
    startDay: Dayjs | null;
    endDay: Dayjs | null;
    nights: number;
    creatingReservation: boolean;
    onActionClick: () => void;
    onDeleteClick: () => void;
    onClearDays: () => void;
    notes: string,
    setNotes: (notes: string) => void;
}

const BottomBar: React.FC<BottomBarProps> = ({
                                                 startDay,
                                                 endDay,
                                                 nights,
                                                 creatingReservation,
                                                 onActionClick,
                                                 onDeleteClick,
                                                 onClearDays,
                                                 notes,
                                                 setNotes,
                                             }) => {
    // console.log('start-day-valid', endDay)

    const {usedTheme, selectedBooking, isMobile, setSelectedBooking} = useGlobalContext()

    const step = selectedBooking?.startDay
        ? 0
        : !startDay?.isValid() && !endDay?.isValid()
            ? 1
            : !endDay?.isValid()
                ? 2
                : 3;

    return (
        <Paper style={{
            position: 'fixed',
            bottom: 0, left: 0,
            right: 0,
            backgroundColor: usedTheme?.palette.background.paper,
            zIndex: 30,
        }} elevation={3}>
            <Grid
                container
                spacing={1}
                alignItems="center"
                className="p-5 py-7"
            >
                {step === 1 && (
                    <Grid item xs={12} className="text-xl" style={{
                        color: usedTheme?.palette.getContrastText(usedTheme?.palette.background.paper),
                    }}>
                        Create new reservation - select start date from the calendar
                    </Grid>
                )}
                {step === 2 && (
                    <Grid item xs={12} className="text-xl" style={{
                        color: usedTheme?.palette.getContrastText(usedTheme?.palette.background.paper),
                    }}>
                        Select reservation end date
                    </Grid>
                )}
                {step === 3 && (
                    <Grid item xs={12} className="text-xl pb-3 pt-6" style={{
                        color: usedTheme?.palette.getContrastText(usedTheme?.palette.background.paper),
                    }}>
                        Save new reservation
                    </Grid>
                )}
                {step === 3 && (
                    <Grid
                        container
                        justifyContent="center"
                        className="py-3"
                    >
                        <Grid item xs={12}
                              className="py-3 font-bold"
                        >
                            <Chip
                                label={startDay?.format('DD.MM.YYYY') + ' - ' + endDay?.format('DD.MM.YYYY')}
                                className="mr-2 chip-dates"
                                variant="outlined"
                            />
                            <Chip
                                label={nights + ' nights'}
                                style={{
                                    background: usedTheme?.palette.secondary.light,
                                }}
                                className="mx-2 chip-days"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            className="py-3 px-6"
                            style={{
                                maxWidth: '800px',
                            }}
                        >
                            <Grid item xs={12} className="pb-5">
                                <TextField
                                    value={notes}
                                    label="Notes (optional)"
                                    onChange={(e) => setNotes(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6} className="pb-4">
                                <Button
                                    onClick={onActionClick}
                                    color="success"
                                    variant="contained"
                                    fullWidth={isMobile}
                                    disabled={creatingReservation}
                                >
                                    {creatingReservation ? <CircularProgress size={24} color="inherit"/> : 'Reserve'}
                                </Button>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6} className="pb-4">
                                <Button onClick={onClearDays} color="warning"
                                        disabled={creatingReservation}
                                        fullWidth={isMobile}
                                        variant="outlined"
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {step === 0 && (
                    <Grid
                        container
                        justifyContent="center"
                        className="py-3"
                    >
                        <Grid item xs={12}
                              className="py-3 font-bold"
                        >
                            <Chip
                                label={selectedBooking?.startDay?.format('DD.MM.YYYY') + ' - ' + selectedBooking?.endDay?.format('DD.MM.YYYY')}
                                className="mr-2 chip-dates"
                                variant="outlined"
                            />
                            <Chip
                                label={dayjs(selectedBooking?.endDay.add(1, 'day')).diff(selectedBooking?.startDay, 'days') + ' nights'}
                                style={{
                                    background: usedTheme?.palette.secondary.light,
                                }}
                                className="mx-2 chip-days"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            className="py-3 px-6"
                            style={{
                                maxWidth: '800px',
                            }}
                        >
                            <Grid item xs={12} className="pb-4" style={{
                                color: usedTheme?.palette.getContrastText(usedTheme?.palette.background.paper),
                            }}>
                                {selectedBooking?.ownerReservation ? selectedBooking.ownerNotes : 'Costasolrent.com booking'}
                            </Grid>
                            {selectedBooking?.ownerReservation && (
                                <Grid item xs={isMobile ? 12 : 6} className="pb-4">
                                    <Button
                                        onClick={onDeleteClick}
                                        color="error"
                                        fullWidth={isMobile}
                                        variant="outlined"
                                        disabled={creatingReservation}
                                    >
                                        {creatingReservation ? <CircularProgress size={24} color="inherit"/> : 'Delete my reservation'}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={selectedBooking?.ownerReservation && !isMobile ? 6 : 12} className="pb-4">
                                <Button onClick={() => {
                                    setSelectedBooking(null)
                                }} color="warning"
                                        disabled={creatingReservation}
                                        fullWidth={isMobile}
                                        variant="outlined"
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default BottomBar;
