import axios, {AxiosResponse} from 'axios';
import {Reservation} from "../store/types";

export const apiUrl = window.location.hostname.includes('costasolrent.com')
    ? 'https://api-sandbox.costasolrent.com/api/costa-sandbox/'
    : '/api/costa-sandbox/' // For localhost

interface User {
    username: string;
    password: string;
}

const Api = {
    handleAPIErrors: (response: AxiosResponse): any => {
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            alert('error')
            return response.status;
        }
    },

    createErrorResponse: (): AxiosResponse => {
        return {
            data: null,
            status: 500,
            statusText: 'Internal Server Error',
            headers: {},
            config: {},
        } as AxiosResponse;
    },

    sendRequest: async (method: string, url: string, data?: any): Promise<AxiosResponse> => {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios({
                method,
                url: `${apiUrl}${url}`,
                data,
                headers,
            });

            await Api.handleAPIErrors(response);

            return response;
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                await Api.handleAPIErrors(error.response as AxiosResponse);
                return error.response as AxiosResponse
            } else {
                // Error: Network error or other non-HTTP-related error
                console.error('Network error or non-HTTP-related error:', error.message);
                return Api.createErrorResponse();
            }
        }
    },

    loginUser: async (user: User): Promise<AxiosResponse> => {
        return await Api.sendRequest('POST', 'login', user);
    },

    createReservation: async (data: Reservation): Promise<AxiosResponse> => {
        return await Api.sendRequest('POST', 'reservations', data);
    },

    deleteReservation: async (id: string): Promise<AxiosResponse> => {
        return await Api.sendRequest('DELETE', 'reservations/' + id, {});
    },
}

export default Api;

/*

// Example usage
const api = new Api('https://your-api-url');

(async () => {
    try {
        await api.loginUser({username: 'exampleUser', password: 'examplePassword'});
        await api.createItem({ /!* item data *!/});
        const items = await api.fetchListItems('exampleModel', { /!* filters *!/}, { /!* queries *!/});

        console.log('Items:', items);
    } catch (error: any) {
        console.error(error.message);
    }
})();
*/
